import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import { SignupComponent } from './components/user/signup/signup.component';
import { SigninComponent } from './components/user/signin/signin.component';
import { AuthGuard } from 'src/app/core/auth/auth.guard';
import { HomeComponent } from './pages/home/home.component';
import { OwnershipComponent } from './pages/ownership/ownership.component';
import { ServiceDepartmentsComponent } from './pages/service-departments/service-departments.component';
import { MedicalDevicesComponent } from './pages/medical-devices/medical-devices.component';
import { StrategicComponent } from './pages/strategic/strategic.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { SampleProjectsComponent } from './pages/sample-projects/sample-projects.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CareersComponent } from './pages/careers/careers.component';
import { EventsComponent } from './pages/events/events.component';
import { HigherManagementStructureComponent } from './pages/higher-management-structure/higher-management-structure.component';
import { CareerDetailsComponent } from './pages/career-details/career-details.component';
import { NewsComponent } from './pages/news/news.component';
import { DetailsComponent } from './pages/details/details.component';
import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
import { MainComponent } from './main.component';
import { DeviceDetailsComponent } from './pages/device-details/device-details.component';

const routes: Routes = [{
    path: "",
    component: MainComponent,
    children: [
        {
            path: '',
            component: HomeComponent,
           
        },
        {
            path: 'home',
            component: HomeComponent
        },
        {
            path: 'strategic-direction',
            component: StrategicComponent
        },
        {
            path: 'partners',
            component: PartnerComponent
        },
        {
            path: 'events',
            component: EventsComponent
        },
        {
            path: 'news',
            component: NewsComponent
        },
        {
            path: 'news-details/:id',
            component: DetailsComponent
        },
        {
            path: 'event-details/:id',
            component: DetailsComponent
        },
        {
            path: 'project-details/:id',
            component: ProjectDetailsComponent
        },
    
        {
            path: 'service-departments',
            component: ServiceDepartmentsComponent
        },
        {
            path: 'sample-projects',
            component: SampleProjectsComponent
        },
        {
            path: 'medical-devices',
            component: MedicalDevicesComponent
        },
        {
            path: 'medical-devices/:id',
            component: MedicalDevicesComponent
        },
        {
            path: 'device-details/:id',
            component: DeviceDetailsComponent
        },
        {
            path: 'contact-us',
            component: ContactComponent
        },
        {
            path: 'higher-management-structure',
            component: HigherManagementStructureComponent
        },
        {
            path: 'careers',
            component: CareersComponent
        },
        {
            path: 'apply/:id',
            component: CareerDetailsComponent
        },
        {
            path: 'ownership',
            component: OwnershipComponent
        },  
        {
            path: 'login',
            component: SigninComponent,  
            canActivate:[AuthGuard]
        },]
   

}];

export const ModuleRouting: ModuleWithProviders = RouterModule.forChild(routes);